
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  SelectedCountry,
  RedCapital,
  TitleTemplateDashboard
} from "@/configuration";
// @ts-ignore
import jsPDF from "jspdf";
import API from "@/api";
import moment from "moment";
import { mapState } from "vuex";

import TableResponsive from "@/components/table/TableResponsive.vue";

@Component({
  metaInfo: {
    title: "Contratos",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  computed: {
    ...mapState(["user", "setOpenMenuPanel"]),
    setOpenMenuPanel(){
      return this.$store.state.openMenuPanel;
    }
  },
  data() {
    return {
      SelectedCountry,
      RedCapital,
      canShow: false,
      search: "",
      contracts: null,
      objTable: null,
      tableData:{
        headers: [
        {
          text: "Nombre contrato",
          align: "center",
          value: "contrato.nombre"
        },
        {
          text: "Fecha",
          align: "center",
          value: "created_at"
        },
        {
          text: "Estado",
          align: "center",
          value: "estado_id"
        }/*,
        {
          text: "Descargar",
          sortable: false
        }*/
      ],
      msjEmpty: 'No existen registros en tu cuenta',
        rowsPerPage: {
          txt: 'Registro por página',
          itm: [10, 25, 50, { text: 'Todos', value: -1 }]
        },
        pagination: {
          sortBy: "created_at",
          descending: true,
          page: 1
        },

      },
      headers: [
        {
          text: "Nombre contrato",
          value: "contrato.nombre"
        },
        {
          text: "Fecha",
          value: "created_at"
        },
        {
          text: "Estado",
          value: "estado_id"
        }/*,
        {
          text: "Descargar",
          sortable: false
        }*/
      ]
    };
  },
  async beforeMount() {
    this.$data.contracts = await API.documents.contracts();
    //console.log(this.$data.contracts)
    /* Formateando Data */
		let propsObjTableAll = {
			style: `text-align: center;
					font-size: 0.7rem;
					font-weight: 500;
					color: #8b8b8b
          heigth: 10vh`
		}
		let propsObjTableButtons = {
			style: `display: flex;
					justify-content: center;`
		}
		Array.from(this.$data.contracts, contratos =>{
			// @ts-ignore
			contratos.datosEnTabla;	
      
			let objTable = {
				nombre:{
					// @ts-ignore
					value: contratos.contrato.nombre,
					label: 'Nombre contrarto',
					props: propsObjTableAll
				},
        fecha:{
					// @ts-ignore
					value: contratos.fecha,
					label: 'Fecha',
					props: propsObjTableAll
				},
        estado:{
          // @ts-ignore
          value: contratos.estado.descripcion,
          label: 'Estado',
          props: propsObjTableAll
        }

			}
      // @ts-ignore
      console.log(contratos.fecha)
			//@ts-ignore
			contratos.datosEnTabla = objTable
      console.log(objTable)
		})
    this.$data.canShow = true;
  },
  methods: {
    generatePDF(id: number) {
      switch (id) {
        // case 1:
        //   // @ts-ignore
        //   this.genDefinitionsPDF();
        //   break;
        case 1:
          // @ts-ignore
          this.genContratoPDF();
          break;
        default:
          console.log("Error ");
          break;
      }
    },
    genContratoPDF() {
      setTimeout(() => {
        let doc = new jsPDF();
        let source = document.getElementById("pdfTerminosCondiciones");
        doc.fromHTML(source, 10, 10, {
          width: 170
        });

        doc.save(
          `contrato-terminos-condiciones-${this.$store.state.user.rut}.pdf`
        );
      }, 500);
    },
    // genDefinitionsPDF() {
    //   setTimeout(() => {
    //     let doc = new jsPDF();
    //     let source = document.getElementById("pdfDefinicionAntecedentes");
    //     doc.fromHTML(source, 10, 10, {
    //       width: 170
    //     });

    //     doc.save(
    //       `definiciones-y-antecedentes-${this.$store.state.user.rut}.pdf`
    //     );
    //   }, 500);
    // }
  },
  components: {
    TableResponsive,
  }
})
export default class Contracts extends Vue {}
